import React from 'react'
import ReactDOM from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import * as serviceWorker from './serviceWorker'

import './index.css'
import { App } from './App'
import appStore from './stores/appStore'
import { dinankaTheme } from './utils/theme'
import { ThemeProvider } from '@material-ui/styles'

ReactDOM.render(
  <ThemeProvider theme={dinankaTheme}>
    <StoreProvider store={appStore}>
      <App />
    </StoreProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

const hideChatBot = () => {
  if (document.getElementById('zohohc-asap-web-helper-main')) {
    document.getElementById('zohohc-asap-web-helper-main').style.display =
      'none'
  }
}

hideChatBot()

serviceWorker.register()
