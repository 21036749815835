import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyBauNYHJsL1WgwBSd5v7weU2lImKkP5O8M',
  authDomain: 'dainik-datepanchang.firebaseapp.com',
  databaseURL: 'https://dainik-datepanchang.firebaseio.com',
  projectId: 'dainik-datepanchang',
  storageBucket: 'dainik-datepanchang.appspot.com',
  messagingSenderId: '369525657100',
  appId: '1:369525657100:web:ab4c360e9c46416a',
  measurementId: 'G-XYRR4JC0X7'
}
const app = firebase.initializeApp(config)

const functions = firebase.functions()

const analytics = firebase.analytics(app)

// @ts-ignore
const firestore = firebase.firestore(app)

app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
// .then(console.log('User will remain logged in!'))

let persistentFirestore

if (window && window.indexedDB) {
  persistentFirestore = firestore
    .enablePersistence({ synchronizeTabs: true })
    .then(() => Promise.resolve(firestore))
} else {
  persistentFirestore = Promise.resolve(firestore)
}

export {
  persistentFirestore,
  app as firebase,
  firestore,
  functions,
  analytics
}
