import { firebase } from '../stores/firebase'
import moment from 'moment'
import emptyImageWEBP from '../assets/images/no-image.webp'
import emptyImageJPG from '../assets/images/no-image.jpg'
import Dexie from 'dexie'
import { consoleAndThrow } from './miscellaneous'
import { isAndroid, isIOS, isWindows } from 'react-device-detect'

const fileFormat = '.jpg'

const emptyImage = emptyImageJPG

const db = new Dexie('dinank')
db.version(1).stores({
  p: '++id'
})

const local = '' // '_local/'

const fetchImage = async imageRef => {
  try {
    const url = await imageRef.getDownloadURL()
    const data = await (await window.fetch(url)).blob()
    return data
  } catch (error) {
    console.error(
      'Error in downloading file: ' + imageRef.fullPath + '\n' + error.message
    )
    return emptyImage
  }
}

export const getCalendarImage = async (page, useCache) => {
  const storageRef = firebase.storage().ref()

  const docId = `${page}`.padStart(2, '0')
  const imageRef = storageRef
    .child(moment().format('YYYY'))
    .child(`${docId}` + fileFormat)

  return getImage(imageRef, useCache && docId)
}

export const getDailyImage = async (date, useCache) => {
  const storageRef = firebase.storage().ref()

  const docId = moment(date).format('YYYY_MM_DD')
  const imageRef = storageRef
    .child(moment(date).format('YYYY'))
    .child(moment(date).format('YYYY MM DD') + fileFormat)
  return getImage(imageRef, useCache && docId)
}

export const getPanchangImage = async (shaka, page, useCache) => {
  const storageRef = firebase.storage().ref()

  const docId = shaka + '_' + `${page}`.padStart(3, '0')
  const imageRef = storageRef.child(shaka).child(`${page}` + fileFormat)

  return getImage(imageRef, useCache && docId)
}

/// docId is null for useCache false
const getImage = async (imageRef, docId) => {
  let image
  // docId not falsy => useCache
  if (docId) {
    try {
      const doc = await db.p.get({ id: local + docId })
      if (!doc) {
        image = await syncSingleImage(imageRef, docId)
      } else {
        image = doc.i
      }
    } catch (error) {
      console.error('error in getting attachment in DB: \n' + error.message)
    }
  } else {
    // do not use cache
    try {
      image = await fetchImage(imageRef)
    } catch (error) {
      consoleAndThrow('error in get image \n' + error.message)
    }
  }

  return image
}

// takes care of updated field and wisely syncronizes image if necessary
const syncSingleImage = async (imageRef, docId, updated) => {
  let docToAdd, metadata

  // called from getImage, which reads local image if useCache, else fetches.
  // This is useCache case and local image is not found
  if (!updated) {
    try {
      metadata = await imageRef.getMetadata()
    } catch (error) {
      consoleAndThrow(
        `error in getting metadata for ${docId} \n${error.message}`
      )
      return null
    }
  } else {
    // called from addImage, where updated field is known already. so trip to server for value not needed
    metadata = { updated }
  }

  try {
    docToAdd = await db.p.get({ id: local + docId })
    if (!docToAdd) {
      docToAdd = {
        id: local + docId
      }
    }
  } catch (error) {
    consoleAndThrow(
      'error in reading document while adding in DB: \n' + error.message
    )
  }

  if (docToAdd.updated === metadata.updated) {
    return docToAdd.i
  }

  let data = null
  try {
    data = await fetchImage(imageRef)
  } catch (error) {
    consoleAndThrow('error in single image sync \n' + error.message)
  }
  if (!data) {
    return null
  }
  docToAdd.updated = metadata.updated
  docToAdd.i = data

  try {
    await db.p.put(docToAdd)
  } catch (error) {
    consoleAndThrow(
      'Error in local db update for docid ' + docId + '\n' + error.message
    )
  }
  return data
}

export const maintainFreeImages = async () => {
  // console.log('in maintain free images')
  const firstJan = '2019_01_01' // app launched after this date
  const threeDaysBefore = moment(Date.now())
    .add(-3, 'd')
    .format('YYYY_MM_DD')

  try {
    const docs = await db.p
      .where('id')
      .between(firstJan, threeDaysBefore)
      .primaryKeys()
    if (docs.length) await db.p.bulkDelete(docs)
  } catch (error) {
    console.error(error)
  }

  const tenDaysRange = [...Array(10).keys()].map(offset => {
    return moment(Date.now()).add(offset, 'd')
  })

  for (const imageDate of tenDaysRange) {
    await getDailyImage(imageDate, true)
  }
}

export const removeUnnecessaryShakas = async subscriptions => {
  // this app may long live upto shaka 2000
  const min = `${subscriptions ? Math.min(...subscriptions) : 2000}`
  try {
    const docsToDelete = await db.p
      .where('id')
      .below(min)
      .primaryKeys()

    await db.p.bulkDelete(docsToDelete)
  } catch (error) {
    consoleAndThrow('Error in deleting unnecessary images: \n' + error.message)
  }
}

export const removeImages = async toRemoveDocs => {
  await db.p.bulkDelete(toRemoveDocs.map(d => d.id))
}

export const addShaka = async ({ toAddDocs, setSyncProgress }) => {
  // add new shaka entries
  if (toAddDocs.length === 0) return

  const storageRef = firebase.storage().ref()

  const shakaToAdd = toAddDocs[0].id.substr(0, 4)
  const pages = toAddDocs.map(doc => ({
    ...doc,
    name: `${parseInt(doc.id.substr(5, 3))}` + fileFormat
  }))
  try {
    const folder = storageRef.child(shakaToAdd)
    const fileRefs = await folder.listAll()
    let completedCount = 0
    if (setSyncProgress) {
      setSyncProgress({
        shaka: shakaToAdd,
        value: { total: toAddDocs.length, completed: 0 }
      })
    }
    const webps = pages.map(page => ({
      ...page,
      ref: fileRefs.items.find(item => item.name === page.name)
    }))
    for (const webp of webps) {
      if (webp.ref) {
        // if file is deleted from storage, but, due to some reason, corresponding entry in imageUpdates is not deleted, this will be undefined
        await syncSingleImage(webp.ref, webp.id, webp.data.updated)
      }
      if (setSyncProgress) {
        completedCount++
        setSyncProgress({
          shaka: shakaToAdd,
          value: { completed: completedCount }
        })
      }
    }
  } catch (error) {
    consoleAndThrow(
      'Error in downloading images for shaka ' +
        shakaToAdd +
        '\n' +
        error.message
    )
  }
}
