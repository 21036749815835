import React from 'react'
import {
  Dialog,
  CircularProgress,
  Container,
  Box,
  Typography
} from '@material-ui/core'
import { useStoreState } from 'easy-peasy'
import { useLayoutStyle } from '../../layout/style'

export default () => {
  const processingPayment = useStoreState(state => state.processingPayment)
  const classes = useLayoutStyle()

  return (
    <Dialog
      fullScreen
      open={processingPayment}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Container maxWidth='xs' style={{ height: '100%' }}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Typography variant='h3' color='secondary' align='center'>
            Processing Payment
          </Typography>
          <CircularProgress className={classes.progress} />
        </Box>
      </Container>
    </Dialog>
  )
}
