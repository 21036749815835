import React, { useEffect, useState } from 'react'
import posed from 'react-pose'

import { Typography } from '@material-ui/core'
import logo from '../../assets/images/logo.webp'

export const SplashScreen = () => {
  let [pale, setPale] = useState(false)
  useEffect(() => {
    let interval = setInterval(() => {
      setPale(!pale)
    }, 2000)
    return () => clearInterval(interval)
  })
  const Box = posed.div({
    pale: { opacity: 0.5 },
    bright: { opacity: 1 }
  })

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        overflowY: 'hidden'
      }}
      pose={pale ? 'pale' : 'bright'}
    >
      <img style={{ alignSelf: 'center' }} src={logo} alt='logo' />
      <Typography variant='h1' color='primary'>
        {`। दिनांक ।`}
      </Typography>
    </Box>
  )
}
