import React, { useEffect, lazy, Suspense } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useRedirect } from 'hookrouter'
import { betaVersion, version } from '../package.json'
import { SplashScreen } from './components/loaders'

const Layout = lazy(() => import('./layout/Layout'))

export const App = () => {
  useRedirect('/app', '/')
  useRedirect('/app/*', '/')

  const dispatch = useStoreActions(state => state.modify)
  const unsubscribeAuth = useStoreActions(state => state.auth.unregister)
  const unsubscribeFirebase = useStoreActions(
    state => state.fireState.unsubscribe
  )
  const authListner = useStoreActions(state => state.auth.startAuthListener)

  useEffect(() => {
    if (window.location.href.includes('beta')) {
      dispatch({ version: betaVersion })
    } else {
      dispatch({ version: version })
    }
  }, [dispatch])

  useEffect(() => {
    // console.log('App useEffect auth')
    authListner()
    return () => {
      unsubscribeAuth()
      unsubscribeFirebase()
    }
  }, [authListner, dispatch, unsubscribeAuth, unsubscribeFirebase])

  // Firebase not connected or logged In User goes to sign-in page
  return (
    <Suspense fallback={<SplashScreen />}>
      <Layout />
    </Suspense>
  )
}
