import { action, createStore } from 'easy-peasy'
import { authStore } from './auth'
import { firebase, functions, persistentFirestore } from './firebase'
import { fireState } from './fireState'

const byImmer = valueObj => {
  if (typeof valueObj !== 'function') {
    return draft => {
      Object.entries(valueObj).forEach(([key, value]) => {
        draft[key] = value
      })
    }
  } else {
    return valueObj
  }
}

const state = {
  // misc
  title: '',
  subscriptionType: 'free',
  subscriptionShaka: '',
  version: null,
  maxPageNumbers: null,
  // global
  updateAvailable: false,
  online: false,
  drawer: false,
  fullScreen: false,
  // stores
  fireState: fireState,
  auth: authStore,
  // dialogs
  confirmDialog: false,
  shareDialog: false,
  loginDialog: false,
  popupError: null,
  // snack
  snackbar: false,
  snackbarStyle: 'success',
  snackbarMessage: '',
  // loaders
  syncProgress: null,
  loading: false,
  preparingCheckout: false,
  processingPayment: false
}

const selectors = {}

const actions = {
  modify: action((oldState, payload) => {
    byImmer(payload)(oldState)
  }),
  setSyncProgress: action((state, payload) => {
    if (!state.syncProgress) {
      state.syncProgress = {}
    }
    state.syncProgress[payload.shaka] = {
      ...state.syncProgress[payload.shaka],
      ...payload.value
    }
    if (
      state.syncProgress[payload.shaka].total ===
      state.syncProgress[payload.shaka].completed
    ) {
      delete state.syncProgress[payload.shaka]
    }
  }),
  resetSyncProgress: action(state => {
    state.syncProgress = null
  }),
  setMaxPageNumber: action((state, { shaka, max }) => {
    if (!state.maxPageNumbers) {
      state.maxPageNumbers = {}
    }
    state.maxPageNumbers[shaka] = max
  })
}

const store = {
  ...state,
  ...selectors,
  ...actions
}

export default createStore(store, {
  injections: { db: persistentFirestore, firebase, functions }
})
