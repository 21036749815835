import { createMuiTheme } from '@material-ui/core'

export const pickersTheme = createMuiTheme({
  palette: {
    primary: { main: '#805a46', contrastText: '#ffffff' }
  },
  overrides: {
    MuiInputBase: { input: { textAlign: 'center' } }
  }
})

export const dinankaTheme = createMuiTheme({
  palette: {
    primary: { main: '#FB6829', contrastText: '#ffffff' },
    secondary: { main: '#805a46', contrastText: '#ffffff' }
  },
  status: {
    // My business variables
    danger: '#c45c33'
  },
  typography: {
    fontFamily: [
      '"Noto Sans"',
      '"Open Sans"',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiTab: {
      wrapper: {
        fontSize: 'large'
      }
    },
    MuiButton: { label: { textTransform: 'capitalize' } },
    MuiAppBar: { colorPrimary: { backgroundColor: '#fb6829ed' } },
    MuiToolbar: {
      regular: { minHeight: 'auto' }
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 'larger'
      }
    },
    MuiCardHeader: {
      title: {
        color: '#FB6829'
      },
      subheader: { color: '#fd5627' }
    }
  }
})
