import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Typography from '@material-ui/core/Typography'

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'

let useStyle = makeStyles(theme => ({
  root: {
    // color: 'darkgreen',
    // backgroundColor: 'lightgreen'
  }
}))

export const GenericLoader = props => {
  let { open, title, ...rest } = props
  let classes = useStyle()
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      {...rest}
      open={open}
    >
      <DialogTitle className={classes.root} id='confirmation-dialog-title'>
        {title}
      </DialogTitle>
      <Divider style={{ marginBottom: '10px' }} />
      <DialogContent style={{ textAlign: 'center' }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}
