import { makeStyles } from '@material-ui/core'

export const useLayoutStyle = makeStyles(
  ({ palette: { background }, spacing, breakpoints }) => ({
    appDiv: {
      height: 'inherit',
      display: 'flex',
      // flexGrow: 1,
      flexDirection: 'column',
      // alignItems: 'stretch',
      backgroundColor: background.default
      // overflowY: 'auto'
    },
    // transitionContainer: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   height: '100%'
    // },
    linearProgress: {
      height: '2px'
    },
    iconButtonRoot: {
      padding: spacing(1)
    },
    svgIconRoot: {
      fontSize: spacing(5),
      [breakpoints.down('sm')]: {
        fontSize: spacing(3)
      }
    },
    error: {
      color: 'red',
      fontFamily: "'Khula', sans-serif"
    },
    mainContent: {
      display: 'flex',
      flexGrow: 1,
      [breakpoints.up('sm')]: {
        padding: spacing(1)
      }
    },
    toolbar: {
      background: 'linear-gradient(to left, #f0981980, #ff512f80)'
    },
    // autoMinHeight: {
    //   minHeight: 'auto'
    // },
    menuButton: {
      marginRight: spacing(2)
    },
    toolbarTitle: {
      flexGrow: 1
    },
    link: {
      margin: spacing(1, 1.5),
      color: 'white',
      cursor: 'pointer'
    },
    progress: {
      marginTop: spacing(6)
    }
  })
)
