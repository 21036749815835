import { action, computed, thunk, thunkOn } from 'easy-peasy'
import firebase from 'firebase/app'
import { navigate } from 'hookrouter'
import _ from 'lodash'
import moment from 'moment'
import { addShaka, removeImages, removeUnnecessaryShakas } from '../utils/file'

// * The subscription (function) is defined outside of the store
// * so the reference is available to the unsubscribe function
// * With Firestore, you remove the listener by just calling it..
let subscriptions

const initialState = {
  user: {},
  shakas: [],
  years: [],
  orderAPIResponse: {},
  prices: {
    premium: null,
    archives: null
  },
  archivesFirstShaka: null,
  version: {
    beta: null,
    production: null
  }
}

const state = initialState

const selectors = {
  isUser: computed(state => !!state.user.id),
  hasPremiumSub: computed(
    state =>
      state.user.data &&
      state.user.data.subscriptions.premium &&
      state.user.data.subscriptions.premium.some(shaka => {
        if (state.shakas.length) {
          if (state.shakas.length === 1) {
            return shaka === state.shakas[0]
          } else {
            return shaka === state.shakas[0] || shaka === state.shakas[1]
          }
        } else return false
      })
  ),
  hasArchivesSub: computed(
    state =>
      state.user.data &&
      state.user.data.subscriptions.archives &&
      (state.user.data.subscriptions.archives === true
        ? moment().isBefore(moment('01-03-2021', 'DD-MM-YYYY'))
        : moment().isBefore(state.user.data.subscriptions.archives.toDate()))
  )
}

const actions = {
  /**
   * When upstream changes are synced with state, this function is called
   *
   * @param {*} payload
   */
  reset: action(state => {
    state.user = {}
    state.orderAPIResponse = {}
  }),
  updateUser: action((state, payload) => {
    state.user = payload
  }),
  updateShakas: action((state, payload) => {
    state.shakas = payload
  }),
  updateYears: action((state, payload) => {
    state.years = payload
  }),
  updateFirstShaka: action((state, payload) => {
    state.archivesFirstShaka = payload
  }),
  setResponse: action((state, payload) => {
    state.orderAPIResponse = payload
  }),
  updatePrice: action((state, payload) => {
    state.prices[payload.type] = payload.price
  }),
  updateVersion: action((state, payload) => {
    state.version[payload.stage] = payload.version
  })
}

const effects = {
  subscribe: thunkOn(
    (actions, storeActions) => storeActions.auth.setAuthState,
    async (
      actions,
      target,
      { dispatch, getStoreState, getState, getStoreActions, injections }
    ) => {
      const { db } = injections
      const authUser = getStoreState().auth.user
      const userId = authUser && authUser.email

      if (subscriptions && subscriptions.length > 0) {
        subscriptions.forEach(sub => sub())
      }
      subscriptions = []

      const firestore = await db

      subscriptions.push(
        firestore
          .collection('settings')
          .doc('dinaanka')
          .onSnapshot(doc => {
            if (doc !== undefined) {
              // console.log('dinaanka settings', doc.data())
              actions.updateFirstShaka(doc.data().archivesFirstShaka)
              if (window.location.href.includes('beta')) {
                actions.updateVersion({
                  stage: 'beta',
                  version: doc.data().version.beta
                })
              } else {
                actions.updateVersion({
                  stage: 'production',
                  version: doc.data().version.production
                })
              }

              actions.updatePrice({
                type: 'premium',
                price: doc.data().premiumPrice
              })
              actions.updatePrice({
                type: 'archives',
                price: doc.data().archivesPrice
              })

              if (
                moment().isSameOrAfter(
                  moment.unix(
                    doc.data().aarambhaDinaanka.slice(-1)[0].dinaanka.seconds
                  )
                )
              ) {
                actions.updateShakas([
                  doc.data().aarambhaDinaanka.slice(-1)[0].shaka
                ])
                actions.updateYears([
                  doc.data().aarambhaDinaanka.slice(-1)[0].year
                ])
              } else if (
                doc.data().aarambhaDinaanka.length >= 2 &&
                moment().isBetween(
                  moment.unix(
                    doc.data().aarambhaDinaanka.slice(-2)[0].dinaanka.seconds
                  ),
                  moment.unix(
                    doc.data().aarambhaDinaanka.slice(-2)[1].dinaanka.seconds
                  )
                )
              ) {
                actions.updateShakas(
                  doc
                    .data()
                    .aarambhaDinaanka.slice(-2)
                    .map(item => item.shaka)
                )
                actions.updateYears(
                  doc
                    .data()
                    .aarambhaDinaanka.slice(-2)
                    .map(item => item.year)
                )
              }
            }
          })
      )

      if (userId) {
        subscriptions.push(
          firestore
            .collection('users')
            .doc(userId)
            .onSnapshot(async doc => {
              if (doc !== undefined) {
                // console.log('data:', doc.data())
                actions.updateUser({ id: doc.id, data: doc.data() })
                const data = doc.data()
                let availableShakas = getState().shakas

                // if live data of setting not updated in local state, in such rare occasion,
                // we do not depend on live data, and get data by one time query i.e. get
                if (availableShakas.length === 0) {
                  availableShakas = await firestore
                    .collection('settings')
                    .doc('dinaanka')
                    .get()
                    .data()
                    .aarambhaDinaanka.slice(-2)
                    .map(item => item.shaka)
                }

                if (!doc.data().useCache) {
                  await removeUnnecessaryShakas()
                }

                let activeSubs
                let archivesSubs

                if (getState().hasArchivesSub) {
                  archivesSubs = _.range(
                    getState().archivesFirstShaka,
                    Number(getState().shakas[0])
                  ).map(as => `${as}`)
                }

                let premiumSubs =
                  data.subscriptions &&
                  data.subscriptions.premium.filter(subscribedShaka =>
                    availableShakas.includes(subscribedShaka)
                  )
                // console.log('premiumSubs', premiumSubs)
                // console.log('before if', activeSubs)

                if (premiumSubs.length > 0) {
                  // console.log('premium exist', activeSubs)
                  activeSubs = [...archivesSubs, ...premiumSubs]
                  // console.log('after concat', activeSubs)
                }
                // console.log('after if', activeSubs)

                await removeUnnecessaryShakas(activeSubs)
                for (const activesub of activeSubs) {
                  subscriptions.push(
                    firestore
                      .collection('imageupdates')
                      .where(
                        firebase.firestore.FieldPath.documentId(),
                        '>=',
                        activesub
                      )
                      .where(
                        firebase.firestore.FieldPath.documentId(),
                        '<',
                        activesub + '_999'
                      )
                      .onSnapshot(async snapshot => {
                        const changes = snapshot.docChanges()
                        const addedImages = changes
                          .filter(change => change.type === 'added')
                          .map(change => ({
                            id: change.doc.id,
                            data: change.doc.data()
                          }))
                        if (addedImages.length > 0) {
                          const maxPageNoDoc = _.maxBy(addedImages, 'id')
                          getStoreActions().setMaxPageNumber({
                            shaka: activesub,
                            max: Number(maxPageNoDoc.id.substr(5))
                          })
                          // console.log(
                          //   'Shaka & Max Pages: ',
                          //   activesub,
                          //   Number(maxPageNoDoc.id.substr(5))
                          // )
                        }
                        let subscribedPremiumShakas = addedImages.filter(img =>
                          premiumSubs.some(pS => img.id.includes(pS))
                        )
                        if (data.useCache) {
                          await addShaka({
                            toAddDocs: subscribedPremiumShakas,
                            setSyncProgress: getStoreActions().setSyncProgress
                          })
                        }
                        const modifiedImages = changes
                          .filter(change => change.type === 'modified')
                          .map(change => ({
                            id: change.doc.id,
                            data: change.doc.data()
                          }))
                          .filter(img =>
                            premiumSubs.some(pS => img.id.includes(pS))
                          )

                        if (data.useCache) {
                          await addShaka({ toAddDocs: modifiedImages })
                        }
                        const deletedImages = changes
                          .filter(change => change.type === 'removed')
                          .map(change => ({
                            id: change.doc.id,
                            data: change.doc.data()
                          }))
                          .filter(img =>
                            premiumSubs.some(pS => img.id.includes(pS))
                          )
                        if (data.useCache) {
                          await removeImages(deletedImages)
                        }
                      })
                  )
                }
              }
            })
        )
        // navigate('/', true)
      } else {
        // navigate('/sign-in', true)
      }
    }
  ),

  unsubscribe: thunk(async actions => {
    // console.log('starting unsubscribe firebase')
    if (subscriptions && subscriptions.length > 0) {
      subscriptions.forEach(sub => sub())
    }
    // * Reset the state
    actions.reset()
    // console.log('done: unsubscribe firebase')
  }),
  updateUserInDB: thunk(
    async (
      actions,
      payload,
      { dispatch, getState, getStoreActions, injections }
    ) => {
      const { db } = injections
      const state = getState()
      const firestore = await db
      await firestore
        .collection('users')
        .doc(state.user.id)
        .update(payload)
    }
  ),

  initiateOrder: thunk(
    async (
      actions,
      payload,
      { dispatch, getStoreState, getStoreActions, injections }
    ) => {
      const { functions } = injections
      const createOrder = functions.httpsCallable('createOrder')
      return createOrder(payload)
        .then(result => {
          // Read result of the Cloud Function.
          // console.log('result:', result.data)
          actions.setResponse(result.data)
        })
        .catch(error => {
          navigate('/', true)
          const update = getStoreActions().modify
          update({
            preparingCheckout: false,
            popupError: {
              title: 'Checkout Error',
              message:
                error.message +
                ". We can't process your request at the moment. Please try again later. If the issue persists please contact us at sampark@datepanchang.com"
            }
          })
          throw Error("Couldn't execute firebase function!", error.message)
        })
        .then(() => navigate('/checkout'))
    }
  ),

  handlePayment: thunk(
    async (
      actions,
      payload,
      { dispatch, getStoreState, getStoreActions, injections }
    ) => {
      const { functions } = injections
      const confirmPayment = functions.httpsCallable('confirmPayment')
      const update = getStoreActions().modify

      navigate('/')
      update({ processingPayment: true })
      return confirmPayment(payload)
        .then(success => {
          update({ processingPayment: false })
        })
        .then(() => {
          update({
            snackbar: true,
            snackbarStyle: 'success',
            snackbarMessage: 'Payment Successful!'
          })
        })
        .catch(err => {
          console.log('After Payment Error: ', err)
          navigate('/', true)
          update({
            processingPayment: false,
            popupError: {
              title: 'Payment Unsuccesful',
              message:
                err.message +
                '. Payment was unsuccesful. Please try again later. If the issue persists please contact us at sampark@datepanchang.com'
            }
          })
        })
    }
  )
}

export const fireState = {
  ...state,
  ...selectors,
  ...actions,
  ...effects
}
