import { action, thunk } from 'easy-peasy'
import 'firebase/app'
import 'firebase/auth'
import { navigate } from 'hookrouter'

const state = {
  isAuthed: false,
  user: null
}

let unRegisterAuthListner

const actions = {
  setAuthState: action((state, payload) => {
    Object.entries(payload).forEach(([key, val]) => {
      state[key] = val
    })
  }),
  unregister: action(() => {
    console.log('starting unsubscribe auth')
    if (unRegisterAuthListner) unRegisterAuthListner()
    console.log('done: unsubscribe auth')
  })
}

const effects = {
  // * The auth listener will fire any time that the authentication state changes.
  // * If there is no user, we flip the isAuthed boolean value, which forces a
  // * redirect back to the /sign-in path.
  startAuthListener: thunk(async (actions, payload, helper) => {
    const { firebase } = helper.injections

    // console.log('starting subscribe auth')
    if (unRegisterAuthListner) unRegisterAuthListner()

    unRegisterAuthListner = firebase.auth().onAuthStateChanged(user => {
      actions.setAuthState({
        isAuthed: !!user,
        user: user
      })
    })
  }),
  logout: thunk(async (actions, payload, { getStoreActions, injections }) => {
    // console.log('in logout')
    const update = getStoreActions().modify
    update({ loading: true })
    const { firebase } = injections
    const reset = getStoreActions().fireState.reset
    return firebase
      .auth()
      .signOut()
      .then(() => {
        update({ loading: false })
        navigate('/sign-in')
        import('./firebase')
          .then(module => module.analytics.logEvent('logout'))
          .catch(e => console.error('couldnt log signout in analytics', e))
        // console.log('done: logout')
      })
      .then(() =>
        actions.setAuthState({
          isAuthed: false,
          user: {}
        })
      )
      .then(reset)
  })
}

export const authStore = {
  ...state,
  ...actions,
  ...effects
}
